<template>
  <auth-layout>
    <div class="mx-auto mb-auto m-md-auto w-75 w-md-50 px-4">
      <form @submit.prevent="go_login">
        <div class="mb-3">
          <label for="username" class="form-label">Username</label>
          <input type="text" v-model="form.username" class="form-control" id="username" placeholder="Username">
        </div>
        <div class="mb-1">
          <label for="password" class="form-label">Password</label>
          <input type="password" class="form-control" id="password" v-model="form.password" placeholder="Password">
        </div>
        <router-link to="/forget">Lupa password ?</router-link>
        <button type="submit" class="btn btn-primary w-100 mt-4">Login</button>
      </form>
    </div>
  </auth-layout>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Modal from "@/components/Modal.vue";
import AuthLayout from "@/layouts/AuthLayout.vue";

export default ({
  name: 'login-form',
  components: {
    Modal,
    AuthLayout
  },
  data() {
    return {
      form: {
        username: null,
        password: null
      },
      modal: false
    }
  },
  methods: {
    ...mapActions({
      login: 'auth/login'
    }),

    async go_login() {
      // this.modal = true
      let params = {
        username: this.form.username,
        password: this.form.password,
        usergroup: "2"

      }
      console.log(params);


      await this.login(params)
        .then((a) => {
          console.log("login", a);
          if (a.code == 200) {
            this.modal = false
            this.$router.push({ 'name': 'dashboard' })
          } else {
            alert(a.message)
            this.modal = false
          }
        })
        .catch((e) => {
          console.log("form login: " + e);
        })
    }

  },

  beforeMount() {
    $('body').removeClass('sidebar-mini').addClass('login-page')
  },

  beforeUnmount() {
    $('body').removeClass('login-page')
  }

})
</script>

<style scoped>
@media (min-width: 768px) {
  .fs-md-2 {
    font-size: 2rem !important;
  }

  .w-md-50 {
    width: 50% !important;
  }
}
.btn-primary {
  color: #fff;
  background-color: #007bff !important;
  border-color: #007bff;
}
</style>