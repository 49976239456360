<template>
    <div class="position-relative">
        <div class="row row-cols-1 row-cols-md-2 vh-100 m-0 z-3">
            <div class="col col-md-6 p-0">
                <div class="d-flex h-100">
                    <div class="mx-auto mt-auto m-md-auto w-50 px-4 mb-5">
                        <img src="@/assets/images/logos/lazismu-with-text.png" class="img-fluid" alt="Logo Lazizmu">
                        <h6 class="text-end fs-md-2">Kabupaten Blitar</h6>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="d-flex h-100">
                    <slot></slot>
                </div>
            </div>
        </div>
        <img src="@/assets/images/bg-footer.jpg" class="position-absolute end-0 top-0 img-fluid z-[-99]"
            style="rotate: 180deg;" alt="">
        <img src="@/assets/images/bg-footer.jpg" class="position-absolute start-0 bottom-0 img-fluid z-[-99]" alt="">
    </div>
</template>

<script>
export default {
    name: 'AuthLayout',
}
</script>

<style scoped>
@media (min-width: 768px) {
    .fs-md-2 {
        font-size: 2rem !important;
    }
}
</style>