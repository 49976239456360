<template>
  <nav class="main-header navbar navbar-expand navbar-white navbar-light">
      <!-- Left navbar links -->
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link" data-widget="pushmenu" href="#" role="button"><i class="fas fa-bars"></i></a>
        </li>
        <li class="nav-item d-none d-sm-inline-block">
          <a href="#" class="nav-link">
            <!-- {{user.fullname}} -->
          </a>
        </li>
      </ul>
    </nav>
</template>

<script>
import { mapGetters } from "vuex";
export default{
  data(){
    return {

    }
  },

  computed:{
    // ...mapGetters({
    //   user: 'auth/user',
    //   token: 'auth/token'
    // }),
  },

  methods:{
    init(){
      // console.log(this.user.fullname);
    }
  },

  mounted(){
    this.init()
  }
}
</script>