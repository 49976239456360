<template>
    <auth-layout>
        <div class="mx-auto mb-auto m-md-auto w-75 w-md-100 px-4">
            <form class="row row-cols-1 row-cols-md-2 align-items-end row-gap-3">
                <div class="col mb-3 mb-md-0">
                    <label for="username" class="form-label">Username</label>
                    <input type="text" class="form-control" id="username" placeholder="JhonDoe"
                        aria-describedby="noteBlock">
                </div>
                <div class="col">
                    <button type="button" class="btn btn-primary col-12 col-md-auto">Reset Password</button>
                </div>
            </form>
            <div class="row my-2">
                <div class="col form-text text-sm">
                    *Password baru akan dikirimkan ke email yang terdaftar
                </div>
            </div>
            <router-link to="/login">Mencoba login ?</router-link>
        </div>
    </auth-layout>
</template>

<script>
import AuthLayout from '@/layouts/AuthLayout.vue';

export default {
    name: 'LupaPassword',
    components: {
        AuthLayout
    },
    data() {
        return {
            // 
        }
    },
    methods: {
        // 
    },
    beforeMount() {
        $('body').removeClass('sidebar-mini').addClass('login-page')
    },
    beforeUnmount() {
        $('body').removeClass('login-page')
    }
}
</script>

<style></style>