<template>

<content-header title="Donatur"></content-header>
<content>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <h6>Daftar Donatur</h6>
          <button class="btn btn-primary btn-sm mb-2" type="button" @click="openModalDonatur()">Tambah Donatur</button>
          <EasyDataTable :headers="donatur.headers" :items="donatur.list">
            <template #item-hp="item">
              {{ item.hp }}
            </template>
            <template #item-status="item">

              <span class="badge bg-olive mr-1" v-if="item.statusDonatur == 'Active'">{{item.statusDonatur}}</span>
              <span class="badge bg-danger mr-1" v-else-if="item.statusDonatur == 'Non Active'">{{item.statusDonatur}}</span>

              <span v-else class="badge bg-warning mr-1">{{ item.statusDonatur }}</span>

              <span class="badge bg-primary pointer mr-1" @click="openModalUpdateDonatur(item)">Update</span>

              <!-- <span class="badge bg-olive pointer mr-1" @click="openModalDonasiUang(item)" v-if="item.statusDonatur == 'Active'">Donasi Uang</span> -->
            </template>
          </EasyDataTable>
        </div>
      </div>
    </div>
  </div>
</content>

<modal v-if="modal.donatur"  @hide-modal="hideModal()">

  <template v-slot:header>
    <span v-if="modal.data.method == 'update'">Update Donatur</span>
    <span v-else>Input Donatur</span>
  </template>

  <template v-slot:content >
    <import-donatur @hide-modal="donaturList()" :modal_data="modal.data"></import-donatur>
  </template>

  <template v-slot:footer></template>
</modal>

<modal v-if="modal.donasi_uang"  @hide-modal="hideModal()" style="height: 90%">
    <template v-slot:header>
      Form Input Donasi

    </template> 
    <template v-slot:content>
      <donasi-uang :modal_data="modal.data"/>
    </template> 
    <template v-slot:footer>
      <button type="button" class="btn btn-danger" @click="hideModal()">Keluar</button>
    </template>
  </modal>

</template>

<script>

import { mapActions, mapGetters } from "vuex";
import axios from 'axios';
import Modal from "@/components/Modal.vue";
import ImportDonatur from "@/components/InputDonatur.vue";
import DonasiUang from "@/views/donasi/DonasiUang.vue";

export default{
  name: 'DonaturView',
  components: {
    Modal, ImportDonatur, DonasiUang
  },
  data() {
    return {
      url: '/api/donatur',
      table:{
        donatur: false
      },
      modal:{
        data: {},
        donatur: false,
        donasi_uang: false
      },

      donatur:{
        list: [],
        headers: [
          {
            text: "nama",
            value: "nama"
          },
          {
            text: "hp",
            value: "hp"
          },
          {
            text: "alamat",
            value: "alamat_lengkap"
          },
          {
            text: "status",
            value: "status"
          }
        ],
        
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user'
    })  
  },

  methods: {
    init(){
      this.donaturList()
    },

    hideModal(){
      this.modal.donatur = false
      this.modal.donasi_uang = false
    },

    addUser(){

    },

    donaturList(){
      this.hideModal()
      let params = {
        nick: this.user.username,
        usergroup: 2
      }
      axios.get(`${this.url}/amil-list-donatur`, {params})
      .then((a)=>{
        console.log(a.data);
        
        if (a.data.code == 200) {
          this.donatur.list = a.data.response
        }
      })
      .catch((e)=>{
        console.log(e);
      })
      .finally(()=>{
      
      })
    },

    async openModalDonatur(){
      this.modal.data = {
        method: 'insert'
      }
      this.modal.donatur = true
    },

    async openModalUpdateDonatur(item){
      this.modal.data = {
        method: 'update',
        data: item
      }

      this.modal.donatur = true
      
    },

    async openModalDonasiUang(item){
      this.modal.donasi_uang = true
      this.modal.data = {
        data: item
      }
    }
  },

  mounted() {
    this.init()
  },
 
}
</script>

<style>

</style>