<template>
  <aside class="main-sidebar sidebar-dark-primary elevation-4">
    <!-- Brand Logo -->
    <a href="/" class="brand-link pr-5 pl-3">
      <img src="@/assets/images/logos/lazismu-text-white.png" alt="AdminLTE Logo" class="img-fluid">
    </a>

    <!-- Sidebar -->
    <div class="sidebar">
      <!-- Sidebar user (optional) -->
      <div class="user-panel mt-3 pb-3 mb-3 d-flex">
        <div class="image">
          <img src="/dist/img/user2-160x160.jpg" class="img-circle elevation-2" alt="User Image">
        </div>
        <div class="info">
          <a href="#" class="d-block">
            {{ user.fullname }}
          </a>
        </div>
      </div>


      <!-- Sidebar Menu -->
      <nav class="mt-2">
        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
          <nav-item icon="fas fa-home" link="/dashboard">
            <template v-slot:parent_item>
              Dashboard
            </template>
          </nav-item>

          <nav-item icon="fas fa-hand-holding-usd" link="/donatur">
            <template v-slot:parent_item>
              Donatur
            </template>
          </nav-item>

          <nav-item icon="fas fa-money-bill-alt" link="/donasi-uang">
            <template v-slot:parent_item>
              Donasi Uang
            </template>
          </nav-item>

          <nav-item icon="fas fa-cog" link="/pengaturan">
            <template v-slot:parent_item>
              Pengaturan
            </template>
          </nav-item>

          

          <nav-item icon="fas fa-times-circle" link="#" @click="logout">
            <template v-slot:parent_item>
              Logout
            </template>
          </nav-item>

        </ul>
      </nav>
      <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
  </aside>
</template>

<script>
import NavItem from '@/layouts/include/NavItem.vue';
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    NavItem
  },
  computed: {
    ...mapGetters({
      user: 'auth/user'
    })
  },
  methods: {
    ...mapActions({
      logout_system: 'auth/logout'
    }),

    selectMenu(item) {
      console.log(item);
    },


    logout() {
      this.logout_system()
    },
  }
};
</script>

<style scoped>
.nav-link {
  color: rgb(206, 206, 206);
}
</style>