import { createRouter, createWebHistory } from 'vue-router'
import store from "@/store";
import MainLayout from '@/layouts/MainLayout.vue'
import DashboardView from '@/views/dashboard/DashboardView.vue'
import Pengaturan from '@/views/Pengaturan.vue'
import DonaturView from '@/views/donatur/DonaturView.vue'
import DonasiuangView from '@/views/donasi/DonasiuangView.vue'

import Login from '../views/Login.vue'
import LupaPassword from '../views/LupaPassword.vue'

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login
  },

  {
    path: '/login',
    name: 'login',
    component: Login
  },

  {
    path: '/forget',
    name: 'forget',
    component: LupaPassword
  },

  {
    path: '/',
    name: 'main',
    component: MainLayout,
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        component: DashboardView
      },
      {
        path: '/donatur',
        name: 'donatur',
        component: DonaturView
      },
      {
        path: '/donasi-uang',
        name: 'donasi-uang',
        component: DonasiuangView
      },
      {
        path: '/pengaturan',
        name: 'pengaturan',
        component: Pengaturan
      }
    ]
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name !== 'login' && to.name !== 'forget') {
    if (store.getters['auth/isLogin'] != true) {
      next('/login')
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
