<template>
  <div class="row d-flex justify-content-center">
    <div class="col-md-8">

      <div class="form-group">
        <label for="donatur">Donatur</label>
        <select name="donatur" id="donatur" class="form-control" v-model="form.donatur">
          <option v-for="item in donatur" :key="item.id" :value="item.id">{{ item.nama }} -- {{ item.alamat_lengkap }}</option>
        </select>
      </div>
      <div class="form-group">
        <label for="tanggal">Tanggal</label>
        <VueDatePicker v-model="date" placeholder="Input Tanggal..." text-input format="yyyy-MM-dd" />
      </div>

      <div class="form-group">
        <label for="jenis_donasi">Jenis Donasi</label>
        <select name="jenis_donasi" id="jenis_donasi" class="form-control" v-model="form.jenis_donasi" >
          <option>--</option>
          <option v-for="item in cbo_jenis_donasi.items" :key="item.id" :value="item.id">{{ item.donasi }}</option>
        </select> 
      </div>

      <div class="form-group">
        <label for="donasi">NominalDdonasi</label>
        <input type="text" class="form-control" id="donasi" v-model="form.donasi">
      </div>

      <div v-if="props.modal_data.method == 'update' && utils.updateImage == false" >
        <div v-if="form.photo_file != null || form.photo_file != ''">
          <img :src="utils.baseImage + props.modal_data.data.photo_file" alt="">
        </div>
        <button class="btn btn-sm btn-danger" @click="() => utils.updateImage = true">Update Photo</button>
      </div>
      <div v-else>
        <div class="form-group">
          <label for="file">file</label>
          <input type="file" class="form-control" id="file" @change="onFileChange">
        </div>
      </div>
      

      <div class="row">
        <button class="btn btn-sm bg-olive rounded-pill ml-auto" @click="simpanDonasiUang()">Simpan Donasi Uang</button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import axios from 'axios';
import moment from 'moment';

const store = useStore()

const date = ref()
const file = ref(null)

const props = defineProps({
  modal_data: {
    type: Object
  }
})

const emit = defineEmits(['closeModal'])

const user = computed(() => {
  return store.getters['auth/user']
})

const utils = reactive({
  url_donasi: '/api/donasi',
  url_donatur: '/api/donatur',
  baseImage: `http://192.168.100.112:8001/uploads/`,
  updateImage: false
})

const donatur = ref([])

const form = reactive({
  id: "",
  donasi: "",
  coa: "",
  jenis_donasi: "",
  username: "",
  photo_file: "",
  donatur: "",
  tanggal: ""
})


const cbo_jenis_donasi = reactive({
  selected_id: '',
  selected_name: '',
  items: []
})

const init = () => {
  fetchDonatur()
  prepareModal()
  cboJenisDonasi()
}

const onFileChange = async (event) => {
  file.value = event.target.files[0]
  console.log(event.target.files[0]);
  
}

const cboJenisDonasi = async () => {
  try {
    const response = await axios.get(`${utils.url_donasi}/cbo-jenis-donasi`)
    console.log("cbo jenis donasi", response.data); 

    if (response.data.code == 200) {
      cbo_jenis_donasi.items = response.data.response
    }
  } catch (error) {
    console.log(error);
    }
  }


const simpanDonasiUang = async () => {
  const formData = new FormData()
  const username = user.value.username
  const dateValue = date.value
  const donasi = form.donasi
  const jenis_donasi = form.jenis_donasi  
  // const coa = form.coa

  // console.log("jenis donasi", jenis_donasi);
  
  // return

  if (file.value != null) {
    formData.append('file', file.value)
  }

  formData.append('id', form.id)
  formData.append('tanggal', moment(dateValue).format('YYYY-MM-DD HH:mm:ss'))
  formData.append('donasi', donasi)
  formData.append('donatur', form.donatur)
  formData.append('jenis_donasi', jenis_donasi)
  formData.append('username', username)
  formData.append('photo_file', form.photo_file)

  try {
    const response = await axios.post(`${utils.url_donasi}/uang-insert-update`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    console.log(response.data);
    

    if (response.data.code == 200) {
      alert("Berhasil menambahkan donasi uang")
      emit('closeModal')
    }
  } catch (error) {
    console.error(error)
  }
}

const fetchDonatur = async () => {

  try {
    const response = await axios.get(`${utils.url_donatur}/donatur`)
    console.log(response.data);

    if (response.data.code == 200) {
      donatur.value = response.data.response
    }
  } catch (error) {
    console.error(error)
  }
}

const prepareModal = () => {
  // console.log("props modal_data", props.modal_data);

  if (props.modal_data.method == 'update') {
    // console.log(props.modal_data.data);

    date.value = props.modal_data.data.tanggal
    
    form.id = props.modal_data.data.id
    form.donasi = props.modal_data.data.donasi
    form.username = props.modal_data.username
    form.donatur = props.modal_data.data.donatur_id
    form.tanggal = date.value
    form.jenis_donasi = props.modal_data.data.jenis_donasi_id
    cbo_jenis_donasi.selected_id = props.modal_data.data.jenis_donasi_id
    form.photo_file = props.modal_data.data.photo_file
  }
}

const selectJenisDonasi = (event) => {
  let id = event.target.value

  cbo_jenis_donasi.items.find((item) => {
    
    if (item.id == id) {      
      cbo_jenis_donasi.selected_id = item.id
      cbo_jenis_donasi.selected_name = item.donasi
      form.jenis_donasi = item.donasi
    }
  })
}

onMounted(() => {
  init()
  console.log(props.modal_data);

  // if (modal_data.method == 'update') {
  //   prepareModal()
  // }


})

</script>

<style></style>