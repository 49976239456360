<template>
  <content-header title="Donasi Uang"></content-header>
  <content>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h6>Daftar Donasi</h6>
            <!-- <button class="btn btn-primary btn-sm mb-2" type="button" @click="openModalTambahDonasiUang()">Catat Donasi Baru</button> -->
            <form @submit.prevent="donasiUangList()">
              <div class="form-row align-items-center">
                <div class="col-auto">
                  <label class="sr-only" for="tanggal_awal">T Awal</label>
                  <input type="date" class="form-control mb-2" id="tanggal_awal"  v-model="date_range.start_date" placeholder="Tanggal Awal">
                  <!-- <VueDatePicker v-model="date_range.start_date" /> -->
                </div>

                <div class="col-auto">
                  <label class="sr-only" for="tanggal_akhir">T akhir</label>
                  <input type="date" class="form-control mb-2" id="tanggal_akhir" v-model="date_range.end_date" placeholder="Tanggal Awal">
                   <!-- <VueDatePicker v-model="date_range.end_date" /> -->
                </div>

                <div class="col-auto">
                  <button type="submit" class="btn btn-primary mb-2">Cari</button>
                </div>

                <div class="col-auto">
                  <button class="btn bg-olive mb-2" @click="openformDonasi('insert', null)">Tambah Donasi</button>
                </div>

              </div>
            </form>
            <EasyDataTable :headers="donasi.headers" :items="donasi.list">
              <template #item-dokumentasi="item">
                <span class="badge bg-success pointer" @click="openDokumentasi(item)" v-if="item.photo_file != null && item.photo_file != ''">Lihat</span>
                <span v-else>Tidak ada</span>
              </template>

              <template #item-action="item">
                <button class="btn btn-sm bg-orange mr-1" @click="openformDonasi('update', item)">Update</button>
                <button class="btn btn-sm bg-maroon" @click="confirmHapus(item)">Hapus</button>
              </template> 
            </EasyDataTable>
          </div>
        </div>
      </div>
    </div>
  </content>

  <modal v-if="utils.modal.donasi" @hide-modal="hideModal()" style="height: 90%" >
    <template v-slot:header>
      Form Input Donasi

    </template>
    <template v-slot:content>
      <donasi-uang @close-modal="hideAndReload()" :modal_data="utils.modal_data" />
    </template>
    <template v-slot:footer>
      <button type="button" class="btn btn-danger" @click="hideModal()">Keluar</button>
    </template>
  </modal>

  <modal v-if="utils.modal.dokumentasi" @hide-modal="hideModal()">
    <template v-slot:header></template>
    <template v-slot:content>
      <img :src="dokumentasi" alt="dokumentasi">
    </template>
    <template v-slot:footer></template>
  </modal>
</template>

<script setup>
import { ref, onMounted, reactive, computed } from 'vue'
import Modal from '@/components/Modal.vue'
import DonasiUang from '@/views/donasi/DonasiUang.vue'
import axios from 'axios'
import moment from 'moment';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import { useStore } from 'vuex';
import Swal from 'sweetalert2'

const store = useStore()

const style = ref("")

const dokumentasi = ref("")

const date_range = reactive({
  start_date: '',
  end_date: ''
})

const user = computed(() => {
  return store.getters['auth/user']
})


const donasi = reactive({
  headers: [
    { text: 'ID', value: 'id' },
    { text: 'Tanggal', value: 'tanggal' },
    { text: 'Donatur', value: 'donatur' },
    { text: 'Jenis Donasi', value: 'jenis_donasi' },
    { text: 'Nominal', value: 'donasi' },
    { text: 'Dokumentasi', value: 'dokumentasi' },
    { text: 'Status', value: 'status' },
    { text: 'Action', value: 'action' },
  ],
  list: []
})

const utils = reactive({
  url: {
    donasi: '/api/donasi'
  },
  modal: {
    dokumentasi: false,
    donasi: false
  },
  modal_data: {
    method: '',
    data: null
  }
})

function hideModal() {
  utils.modal.donasi = false
  utils.modal.dokumentasi = false
}

const hideAndReload = () => {
  hideModal()
  donasiUangList()
}

function init() {
  const today = moment().format('YYYY-MM-DD')
  
  date_range.start_date = today
  date_range.end_date = today

  donasiUangList()
}


  
const donasiUangList = async () => {
  try {
    let params = {
      start_date: date_range.start_date,
      end_date: date_range.end_date,
      username: user.value.username
    }
    const response = await axios.get(`${utils.url.donasi}/donasi-uang-list`, { params })
    console.log(response.data);
    
    if (response.data.code == 200) {
      donasi.list = response.data.response
    }
  } catch (error) {
    console.log(error);

  }
}

function openDokumentasi(item) {
  dokumentasi.value = `http://192.168.100.112:8001/uploads/${item.photo_file}`
  utils.modal.dokumentasi = true
   
}

const openformDonasi = (method, data) => {
  utils.modal_data.method = method
  utils.modal_data.data = data
  // console.log(utils.modal_data);
  
  utils.modal.donasi = true
}

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 1000,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})

const confirmHapus = (item) => {
  console.log(item);

  Swal.fire({
    title: 'Anda yakin?',
    text: "Data akan dihapus",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Hapus!'
  }).then((result) => {
    if (result.isConfirmed) {
      donasiUangDelete(item.id)
      
    }
  })
}

const donasiUangDelete = async (id) => {
  try {
    let params = {
      username: user.value.username,
      id: id
    }
    const response = await axios.delete(`${utils.url.donasi}/hapus-donasi-uang`, { data:params })
    if (response.data.code == 200) {
      console.log(response.data);
      
      Toast.fire({
        icon: 'success',
        title: response.data.response
      })
      hideAndReload()
    }
  } catch (error) {
    console.log(error);
  }
}

onMounted(() => {
  init()
})
</script>

<style></style>