<template>
  <content-header title="Pengaturan"></content-header>
  <content>
    <!-- <h6>Pengaturan Akun</h6> -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">Perubahan Sandi</div>
              <div class="col-md-6">
                <div class="btn btn-link" @click="openModalUbahsandi()">Ubah Sandi</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </content>
  <modal v-if="modal.update_password">
    <template v-slot:header>
      Form perubahan sandi
    </template>
    <template v-slot:content>
      <div class="row d-flex justify-content-center">
        <div class="col-md-6">
          <div class="form-group">
            <label for="pasword_lama">Password Lama</label>
            <input type="password" class="form-control" id="password_lama" v-model="data_user.password_lama">
          </div>

          <div class="form-group">
            <label for="password_baru">Password Baru</label>
            <input type="password" class="form-control" id="password_baru" v-model="data_user.password_baru">
          </div>

          <div class="form-group">
            <label for="password_baru_retype">Ketik Ulang Password Baru</label>
            <input type="password" class="form-control" id="password_baru_retype" v-model="data_user.password_baru_retype">
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <button type="button" class="btn btn-danger" @click="hideModal()">Keluar</button>
      <button type="button" class="btn btn-primary" @click="simpanSandi()">Simpan</button>
    </template>
  </modal>
</template>



<script>
import Modal from "@/components/Modal.vue";
import { mapGetters, mapActions } from "vuex";
import axios from 'axios';
export default {
  name: "Pengaturan",
  components: {
    Modal
  },

  data() {
    return {
      url: '',
      data_user: {
        password_lama: '',
        password_baru: '',
        password_baru_retype: '',
      },

      modal: {
        update_password: false
      }
    }
  },

  computed: {
    ...mapGetters({
      user: 'auth/user'
    })
  },

  methods: {

    ...mapActions({
      logout_system: 'auth/logout'
    }),

    hideModal() {
      this.modal.update_password = false
    },
    openModalUbahsandi() {
      this.modal.update_password = true
      console.log(this.user);
      

    },

    simpanSandi() {
      if (this.user.password_baru != this.user.password_baru_retype) {
        this.hideModal()
      }

      let params = {
        username: this.user.username,
        old_password: this.data_user.password_lama,
        new_password: this.data_user.password_baru
      }

      // console.log(params);
      

      axios.put(`/update-password`, params)
        .then((a) => {
          console.log(a.data);
          if (a.data.code == 200) {
            this.logout_system()
          }

        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          this.modal.update_password = false
        })
    }

  },
}
</script>

<style></style>